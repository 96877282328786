import React from "react"

import ResponsiveBlock from "../components/shared/responsive-block"

const Home = () => {
    // const transformedData = useMemo(
    //     () => arr.map(row => row.map(el => parseFloat(el))),
    //     [arr]
    // )

    return (
        <ResponsiveBlock>
            {/* <Plot
                data={[
                    {
                        z: transformedData,
                        type: "heatmap",
                        colorscale: "Viridis",
                    },
                ]}
                layout={{ width: 1000, height: 1000, title: "A Fancy Plot" }}
            /> */}
        </ResponsiveBlock>
    )
}

export default Home
